body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  font-family: 'Open Sans', sans-serif;
  color: #222222;
  @media print {   
    overflow: visible;
  }
}

.page-section {
  padding: 60px 0;
  &.gray {
    background-color: $gray1;
  }
  &.blue {
    background-color: $blue-primary;
    color: $white;
  }
  .page-section-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .page-section-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 1;
  }
  .page-section-content {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
    margin-top: 20px;
  }
  .page-section-desc {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
  }
  .page-section-image {
    img {
      width: 100%;
    }
  }
  .page-section-button {
    display: inline-block;
    border: 1px solid $black3;
    color: $black3;
    padding: 14px 24px;
    transition: background-color .4s, color .4s;
    margin-top: 20px;
    &.white {
      border: 1px solid $white;
      color: $white;
    }
    &:hover {
      background-color: $black3;
      color: $white;
      cursor: default;
      &.white {
        background-color: $white;
        color: $black3;
      }
    }
  }
}

// Backgrounds
.bg-gray {
  background-color: $gray7;
}

.bg-dark-gray {
  background-color: #707070;
}

.bg-blue {
  background-color: $blue-primary;
}

.bg-light-blue {
  background-color: $blue-light-90;
}

.bg-light-red {
  background-color: #fdedec;
}

.bg-blue-gradient {
  background-image: url("../images/home-bg.jpg");
  background-color: $blue-primary;
}

.bg-white {
  background-color: $white;
}

.bg-light-green {
  background-color: #e6f8f4
}

// Borders
.radius-4 {
  border-radius: 4px;
}
.radius-8 {
  border-radius: 8px;
}
.radius-half {
  border-radius: 50%;
}

.b-border {
  border-bottom: 1px solid #e8e8e8;
}

.t-border {
  border-top: 1px solid #e8e8e8;
}

.r-border {
  border-right: 1px solid #e8e8e8;
}

.l-border {
  border-left: 1px solid #e8e8e8;
}

.border {
  border: 1px solid #e8e8e8;
}

.border-sortable {
  border: 1px solid #d8d8d8;
}

.border-blue {
  border: 1px solid $blue-primary;
}

.floating-container {
  display: flex;
  justify-content: center;

  &--inner {
    width: 100%;
  }
}

.center {
  text-align: center;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-gray {
  transition: background-color 0.2s;
  &:hover {
    background-color: $gray9;
  }
}

.content-section {
  padding: 50px 0;

  .ant-col {
    padding: 0 15px;
  }

  h2 {
    color: $black5;
    font-weight: 600 !important;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 16px;
  }

  .content-section-text {
    color: $black7;
    font-size: 16px;
  }

  img {
    width: 100%;
  }

  @include for-large {
    padding: 30px 15px;
    h2 {
      font-size: 24px;
    }
    .content-section-text {
      font-size: 14px;
    }
  }

  @include for-medium {
    padding: 30px 15px 0 15px;
    .content-section-text:last-child {
      padding-bottom: 30px;
    }
    img {
      padding-bottom: 30px;
    }
  }

  @include for-small {
    padding: 30px 20px 0 20px;
  }
}

.action-section {
  padding: 50px 0;
  text-align: center;

  h2 {
    color: $white;
    font-weight: 600 !important;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 16px;
  }

  .action-section-text {
    color: $gray1;
    font-size: 16px;
  }

  &.white-bg {
    h2 {
      color: $black5;
    }
    .action-section-text {
      color: $black7;
      font-size: 16px;
    }
  }

  @include for-large {
    padding: 30px;
    h2 {
      font-size: 24px;
      line-height: 1.2;
    }
    .action-section-text {
      font-size: 14px;
    }
  }
}

.white-bg {
  background-color: $white;
}

.gray-bg {
  background-color: $gray1;
}

.blue-bg {
  background-color: $blue-primary;
}

.text-center {
  text-align: center;
}

.text-white {
  color: $white;
}

.page-image {
  height: 500px;
  background-position: center;
  background-size: cover;

  &-overlay {
    background-color: rgba($color: $black, $alpha: 0.3);
    padding: 30px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
  }

  &-title {
    font-size: 70px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
  }

  &-subtitle {
    font-size: 50px;
    font-weight: 200;
    line-height: 0.9;
  }

  img {
    position: absolute;
  }

  // &.weddings {
  //   background-image: url('../images/slide_2.jpg');
  // }
}

.content-section-button {
  @include black-outline-button;
  margin-top: 20px;
}

.page-title-section {
  background-color: $white;
  text-align: center;
  padding: 30px;
  .page-title-1 {
    font-weight: 200;
    font-size: 40px;
    color: $black7;
    line-height: 1;
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
  .page-title-2 {
    font-weight: 600;
    font-size: 60px;
    text-transform: uppercase;
    color: $black5;
    line-height: 1;
    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
}

.page-image-section {
  background-color: $white;
  text-align: center;
  // height: 400px;
  // overflow: hidden;

  img {
    width: 95%;
    // margin-bottom: 40px;
    // box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, .3);
  }
}

.ant-card-meta-title {
  text-align: center;
  font-size: 20px;
}

// Form
.form-label {
  margin: 0 0 4px 2px;
  font-size: 14px;
  font-weight: 400;
  color: $black9;
}
.form-data {
  font-size: 16px;
  margin-bottom: 20px;
}

// Data
.data-label {
  font-size: 16px;
  font-weight: 600;
  color: $black5;
}
.data-text {
  font-size: 16px;
  margin-bottom: 20px;
}

// Admin
.admin-page-header {
  padding: 40px 10px 10px;
  // border-bottom: 1px solid #f1f1f1;
}

.admin-page-back-arrow {
  height: 40px;
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;
  cursor: default;
  background-color: #e8e8e8;
  font-weight: 500;
  padding: 0px 10px;
  border-radius: 6px;
  font-size: 18px;
  transition: background-color .4s, color .4s;
  &:hover {
    background-color: #dadada;
  }
}

.admin-page-title {
  font-size: 24px;
  font-weight: 700;
}

.page-title-button {
  height: 40px;
  display: inline-block;
  padding: 0px 24px;
  background-color: $blue-primary;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  transition: background-color .4s, color .4s;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  &:hover {
    cursor: default;
    background-color: $blue-primary-hover;
  }
  &.secondary {
    background-color: transparent;
    color: $blue-primary;
    border: 1px solid $blue-primary;
  }
}

.admin-page-content-box {
  // border-top: 1px solid #ccc;
  // padding: 20px;
  background-color: $white;
  // box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, .1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &.add-padding {
    padding: 20px;
  }
}

.content-box-section-title-container {
  margin: 0 0 10px 10px;

  .content-box-section-title-edit {
    display: none;
    font-size: 16px;
    color: $black7;
    padding-top: 3px;
  }

  &:hover {
    .content-box-section-title-edit {
      display: block;
    }
  }
}

.content-box-section-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
}

// INPUTS
.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-form-item .ant-input, .ant-form-item .ant-input-password {
  // padding: 8px 10px;
  // font-size: 15px;
}

.ant-form-item  :not(.ant-input-password) > .ant-input {
  &:hover {
    border: 1px solid #CCC;
  }
  &:focus {
    border: 1px solid $blue-primary;
    box-shadow: none;
  }
}

.ant-form-item :not(.ant-input-affix-wrapper-focused).ant-input-affix-wrapper:hover {
  border-color: #CCC;
}

.ant-form-item .ant-input-affix-wrapper-focused {
  border: 1px solid $blue-primary;
  box-shadow: none;
}

.admin-icon-circle {
  background-color: #F5F5F5;
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #909090;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .4s, color .4s;
  &:hover {
    background-color: #ececec;
  }
}

.ant-btn.admin-small-button {
  height: 36px;
  // width: 110px;
  display: inline-block;
  background-color: $blue-primary;
  color: white;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: none;
  transition: background-color .4s, color .4s;
  &:hover, &:focus, &:active {
    color: $white;
    cursor: default;
    background-color: $blue-primary-hover;
  }
  &.secondary {
    background-color: transparent;
    color: $blue-primary;
    border: 1px solid $blue-primary;
  }
}

.admin-link {
  // height: 36px;
  // width: 110px;
  display: inline-block;
  color: $blue-primary;
  font-size: 14px;
  font-weight: 600;

  // transition: background-color .4s, color .4s;
  &:hover, &:focus, &:active {
    color: $blue-primary-hover;
    cursor: default;
    // background-color: $blue-primary-hover;
  }
}

.admin-modal-footer {
  margin-top: 20px;
  text-align: right;
}

// Sign In / Sign Up flow
.center-content-page {
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: $gray9;
  padding: 50px 30px 50px;
  overflow-y: auto;

  @include for-small {
    padding: 80px 20px 20px;
  }

  .center-content-box {
    flex: 0;
    background-color: $white;
    // margin: 30px 0 30px 0;
    border: 1px solid #eee;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    max-width: 500px;
    border-radius: 10px;

    &.w-400 {
      max-width: 400px;
    }
  
    &--title {
      padding: 50px 20px 0px;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
      // border-botom: 1px solid #E0E0E0;
      color: $black3;
    }
  
    &--subtitle {
      padding: 30px 40px 0px;
      text-align: center;
      color: $black3;
    }
  
    &--link-container {
      text-align: center;
      padding-top: 20px;
    }
  
    &--link {
      color: $blue-primary;
  
      &:hover {
        cursor: default;
        color: $blue-primary-hover;
      }
    }
  
    &--form {
      padding: 30px 20px 20px;
  
      // @include for-small {
      //   padding: 30px 20px;
      // }
    }
  }
}

.question-row {
  cursor: default;
  border: 1px solid #CCC;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;

  &.selected {
    border: 1px solid $blue-primary;
  }

  &--icon {
    display: flex;
    flex: 0;
    height: 40px;
    width: 40px;
    flex-basis: 40px;
    // background-color: #f9f9f9;
    background-color: $blue-light-90;
    align-items: center;
    justify-content: center;
    // align-content: center;
    flex-direction: row;
    // text-align: center;
    font-size: 20px;
    border-radius: 8px;
    color: $blue-primary;
    // border: 1px solid #CCC;
  }

  &--label {
    font-weight: 700;
    margin-left: 5px;
    line-height: 1.2;
  }

  &--description {
    margin-left: 5px;
    font-size: 12px;
    white-space: pre-line;
    background-color: $gray7;
    padding: 10px;
    margin-top: 5px;
    border-radius: 8px;
  }

  &--type {
    color: $text-light-gray;
    // font-weight: 700;
    margin-left: 10px;
    line-height: 1.2;
  }
}

.question-row-container {
  list-style: none;
  padding: 0;
  margin: 0;
}

.question-row-dragging {
  background-color: $white;
  // padding: 12px;
  // border: 1px solid $gray1;
  // margin-bottom: 10px;
  list-style: none;
  border-top: 1px solid #dedede;
}

.special-event-row-container {
  list-style: none;
  padding: 0;
  margin: 0;
}

.special-event-row-dragging {
  background-color: $white;
  list-style: none;
}

.pricing-guide-row-dragging {
  margin-top: -10px;
  list-style: none;
}

.timeline-card {
  cursor: default;
  display: flex;
  flex-direction: row;
  // background-color: $white;
  border-bottom: 1px solid #dedede;
  user-select: none;
  // margin-bottom: 10px;
  // border-radius: 8px;

  &--col-1 {
    flex: 1;
    // border-right: 1px solid #dedede;
  }

  &--col-2 {
    display: flex;
    flex: 0;
    align-items: center;
  }

  &--details-row {
    display: flex;
    flex-direction: row;
    padding: 15px 0px;
  }

  &--time {
    display: flex;
    align-items: center;
    font-weight: 700;
    border-right: 1px solid #D8D8D8;
    padding: 0px 15px;
    min-width: 100px;
    justify-content: center;
  }

  &--details {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-items: center;
    padding: 5px 15px;

    &--row-1 {
      display: flex;
      flex-direction: row;
    }
  }
  &--name {
    flex: 1;
    font-weight: 700;
    line-height: 1.1;
    margin-top: 2px;
  }
  &--duration {
    flex: 0;
    & > div {
      font-size: 10px;
      background-color: #f1f1f1;
      padding: 1px 8px;
      border-radius: 15px;
    }
  }
  &--notes {
    font-size: 12px;
    color: $text-light-gray;
    line-height: 1.1;
    margin-top: 6px;
  }

  &--music-row {
    display: flex;
    flex-direction: row;
    background-color: #F6F6F6;
    // margin: 10px 0px 0px;
    margin: 0px 15px 10px;
    padding: 10px 10px;
    border-radius: 8px;
    align-items: center;
    // @include for-medium {
    //   margin: 0px 15px 15px;
    // }
  }
  &--music-image {
    flex: 0;
    img {
      border-radius: 4px;
    }
  }
  &--music-details {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
  }
}

.duration-button {
  cursor: default;
  background-color: #f1f1f1;
  // border: 1px solid #d9d9d9;
  margin: 4px;
  text-align: center;
  padding: 10px 0px;
  border-radius: 8px;
  &.selected {
    background-color: $blue-primary;
    color: $white;
  }
}

.music-search-bar {
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 8px;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px 20px 20px 0px;
  overflow-y: auto;


  // position: fixed;
  // top: 136px;
  // left: 240px;
  // bottom: 0;
  // width: 400px;
  // background-color: $white;
  // border-right: 1px solid #dedede;
  // overflow-y: auto;

  .music-search-field-container {
    // background-color: $white;
    flex: 0;
    padding: 15px;
    border-bottom: 1px solid #dedede;
    width: 100%;
    // position: fixed;
    // right: 0;
    // top: 159px;
    // width: 399px;
    // z-index: 3;

  }

  .music-search-results-container {
    // margin-top: 85px;
    flex: 1;
    overflow-y: auto;
  }

  .music-search-field {
    // border: none;
    padding: 15px 20px;
    outline: none;
    border-radius: 8px;
    background-color: $gray7;
    input {
      background-color: $gray7;
    }
    &:focus {
      outline: none;
    }
  }

  .music-search-result {
    margin: 0 15px;
    padding: 10px 0;
    border-bottom: 1px solid #dedede;
  }

  .music-result-text {
    // width: 200px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.music-playlist-song {
  display: flex;
  flex: 1;
  flex-direction: row;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  // background-color: $white;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  // border-radius: 8px;
  // margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #dedede;

  // max-width: calc(100vw - 680px);
  @include for-large {
    max-width: calc(100vw - 280px);
  }
  @include for-medium {
    max-width: calc(100vw - 40px);
  }
  @include for-small {
    max-width: calc(100vw - 30px);
  }

  &--col {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    justify-content: center;
  }

  .music-playlist-song-text {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  img {
    border-radius: 4px;
  }
}

.music-search-tabs {
  display: flex;
  flex-direction: row;
  .music-search-tab {
    cursor: default;
    flex: 1;
    text-align: center;
    border-bottom: 1px solid #CCC;
    font-size: 15px;
    font-weight: 500;
    padding: 15px;
    color: $text-light-gray;
    &.selected {
      font-weight: 700;
      color: $text-color;
      border-bottom: 2px solid $blue-primary;
    }
  }
}

.full-page-layout {
  &--container {
    // background-color: $gray9;
    // display: flex;
    // flex: 1;
    // flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $gray7;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &--header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: $content-header-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF;
    border-bottom: 1px solid #dedede;
  }

  &--body {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: $content-header-height;
    padding: 20px;
    // display: flex;
    // flex: 1;
  }

  &--body-main {
    flex: 1;
    padding: 30px;
  }

  &--body-main-content {
    width: 100%;
    max-width: 1000px;
  }

  // &--body-panel {
  //   position: fixed;
  //   top: $header-height;
  //   right: 0;
  //   bottom: 0;
  //   flex: 0;
  //   background-color: $white;
  //   border-left: 1px solid #dedede;
  //   width: 400px;
  //   flex-basis: 400px;
  //   overflow-y: auto;
  // }
}

.music-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue-light-90;
  color: $blue-primary;
  font-size: 30px;
  height: 50px;
  width: 50px;
  border-radius: 8px;
}

.shadow-card {
  cursor: default;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: border .2s;
  border: 1px solid #FFF;
  box-sizing: border-box;

  &.hover {
    &:hover {
      cursor: default;
      border: 1px solid #CCC;
    }
  }
}

.shadow-card-square {
  cursor: default;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.print-card {
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  // border-radius: 8px;
  padding: 30px 30px 20px 30px;
  margin: 0 20px;
  @media print
  {   
    box-shadow: none;
    padding: 0 30px 20px;
    margin: 0;
  }
}

.special-events-select-song {
  background-color: $gray9;
  padding: 15px;
  margin: 10px 10px 0px 10px;
  border-radius: 8px;
  text-align: center;
}

.ant-tabs-nav-list {
  .ant-tabs-tab {
    padding: 12px 10px;
    font-weight: 600;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $blue-primary;
  }
}

.ant-table {
  .ant-table-thead > tr > th {
    font-weight: 700;
  }
}

.music-modal-search-result {
  padding: 5px 0;
  display: flex;
  flex: 1;
  gap: 15px;

  &--icon {
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: center;
  }

  &--title-artist {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    justify-content: center;
  }

  // padding: 5px 0;

  .music-result-text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.song-card {
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 8px;
  padding: 10px 10px;
  // display: flex;
  // flex: 1;
  // gap: 15px;
  margin-bottom: 10px;

  &.no-shadow {
    box-shadow: none;
    margin-bottom: 0px;
    border-radius: 0px;
    background-color: transparent;
  }

  &--row-1 {
    display: flex;
    flex: 1;
    gap: 15px;
  }

  &--row-2 {
    background-color: $gray1;
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 8px;
    font-size: 12px;
    white-space: pre-wrap;
  }

  &--image {
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: center;
    // img {
    //   border-radius: 4px;
    // }
  }

  &--icon {
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: center;
    margin-right: 5px
  }

  &--title-artist {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    justify-content: center;
  }

  &--text {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--dots-container {
    display: flex;
    background-color: $gray1;
    height: 36px;
    width: 36px;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    transition: background-color 0.2s;
    &:hover {
      background-color: #eeeeee;
    }
  }

  &--playlist-page {
    max-width: calc(100vw);
    @include for-large {
      max-width: calc(100vw - 280px);
    }
    @include for-medium {
      max-width: calc(100vw - 40px);
    }
    @include for-small {
      max-width: calc(100vw - 30px);
    }
  }

  &--special-events-page {
    max-width: 100%;
    @include for-large {
      max-width: calc(100vw - 280px);
    }
    @include for-medium {
      max-width: calc(100vw - 40px);
    }
    @include for-small {
      max-width: calc(100vw - 30px);
    }
  }

  &--music-planning-page {
    max-width: 100%;
    @include for-large {
      max-width: calc(100vw - 280px);
    }
    @include for-medium {
      max-width: calc(100vw - 40px);
    }
    @include for-small {
      max-width: calc(100vw - 110px);
    }
  }
}

.music-modal-search-field-container {
  .music-modal-search-field {
    background-color: $gray7;
    padding: 15px 20px;
    outline: none;
    border-radius: 8px;
    margin-top: 10px;
    input {
      background-color: $gray7;
    }
  }
}

.music-modal-search-results-divider {
  border-bottom: 1px solid #dedede;
  margin: 20px 0;
}

.tab-slider {
  cursor: default;
  background-color: $gray7;
  padding: 4px;
  height: 40px; 
  display: inline-block;
  border-radius: 20px;
  border: 1px solid #f0f0f0;

  &--tab {
    background-color: $gray7;
    height: 100%;
    font-weight: 600;
    padding: 4px 24px;
    border-radius: 16px;
    display: inline-block;
    color: $text-light-gray;

    &.selected {
      background-color: $blue-primary;
      color: $white;
    }
  }
}

.tab-dropdown {
  cursor: default;
  background-color: $gray7;
  padding: 4px;
  height: 50px; 
  display: flex;
  flex-direction: row;
  border-radius: 25px;
  border: 1px solid #f0f0f0;
  align-items: center;

  &--text {
    font-size: 15px;
    font-weight: 700;
    flex: 1;
    text-align: center;
  }

  &--arrow {
    color: $blue-primary;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    flex: 0;
    margin-right: 15px;
  }
}

.timeline-preview-bar {
  background-color: $white;
  padding: 10px 15px;
  text-align: right;
  border-bottom: 1px solid #dedede;
  @media print {   
    display: none;
  }
}

.timeline-preview-row {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 10px 0;
  &--time {
    width: 90px;
    @media (max-width: 768px) {
      width: 80px;
    }
  }
  &--event {
    flex: 1
  }
  &--songs {
    width: 30%;
    @media (max-width: 768px) {
      background-color: #f7f7f7;
      width: 100%;
      flex-basis: 100%;
      padding: 5px 10px;
      margin-top: 8px;
      border-radius: 4px;
    }
    @media print {   
      width: 30%;
      flex-basis: 30%;
      margin-top: 0;
      padding: 0;
    }
  }
  &--songs-header {
    width: 30%;
    @media (max-width: 768px) {
      display: none;
    }
    @media print {   
      display: flex;
    }
  }
}

.sticky-footer {
  // background-color: $white;
  padding: 15px 20px;
  position: fixed;
  bottom: 0px;
  left: 240px;
  right: 0px;
  // border-top: 1px solid #dedede;
  // text-align: right;
  z-index: 1;

  @include for-medium {
    left: 0px;
  }

  @include for-small {
    .small-100 {
      width: 100%;
    }
  }
}

.sticky-footer-full {
  padding: 15px 20px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}

.countdown-card {
  position: absolute;
  left: 30px;
  bottom: -30px;
  right: 30px;
  padding: 10px;

  .countdown-number {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
  }

  .countdown-label {
    font-size: 12px;
    color: $text-light-gray;
  }

  @include for-small {
    left: 20px;
    right: 20px;
    .countdown-number {
      font-size: 18px;
    }
    .countdown-label {
      font-size: 8px;
    }
  }
}

.dots-container {
  display: flex;
  background-color: $gray1;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  transition: background-color 0.2s;
  &:hover {
    background-color: #eeeeee;
  }
}

.plus-container {
  display: flex;
  background-color: $blue-light-90;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  // border: 1px solid $gray9;
}

.blue-link {
  display: inline-block;
  color: $blue-primary;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  transition: color 0.2s;

  &:hover, &:focus, &:active {
    color: $blue-primary-hover;
    cursor: pointer;
  }
}

.red-link {
  display: inline-block;
  color: $red;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;

  &:hover, &:focus, &:active {
    color: $red-hover;
    cursor: pointer;
  }
}

.gray-link {
  display: inline-block;
  color: $text-light-gray;
  font-size: 12px;
  white-space: nowrap;
  user-select: none;
  text-decoration: underline;
  transition: color 0.2s;

  &:hover, &:focus, &:active {
    color: $text-color;
    cursor: pointer;
  }
}


.event-photo-upload {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: $blue-light-90;
  padding: 8px;
  line-height: 0;
  border: 2px solid $white;
  border-radius: 50%;
  color: $blue-primary;
  font-size: 24px;
}

.user-profile-circle {
  background-color: $blue-light-90;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  flex: 0;
  flex-basis: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: $blue-primary;
  font-size: 28px;
  &:hover {
    cursor: default;
  }
  &.small {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    flex-basis: 40px;
    font-size: 20px;
  }

  &.x-small {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    flex-basis: 30px;
    font-size: 16px;
  }
}

.rounded-modal {
  .ant-modal-content {
    border-radius: 8px;
  }
}

.modal-bg-gray {
  .ant-modal-content {
    background-color: #F7F7F7;
  }
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
}

// Customize lists
.list-search-input.ant-input-affix-wrapper {
  background-color: $gray7;
  border-radius: 8px;
  input {
    background-color: $gray7;
  }
}

.list-select-filter.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
}

// Tab Bar
.tab-bar {
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 10px;
  
  &--tab {
    display: inline-block;
    padding: 5px 15px;
    background-color: #ececec;
    color: $text-light-gray;
    font-weight: 700;
    margin-bottom: -1px;
    border-radius: 4px;
    margin-right: 5px;
    transition: background-color .2s, color .2s;

    &:hover {
      cursor: default;
      background-color: #e2e2e2;
      color: $text-color;
    }

    &.selected {
    //  border-bottom: 3px solid $blue-primary;
    //  color: $text-color;
     background-color: $blue-primary;
     color: $white;
     &:hover {
      background-color: $blue-primary;
      color: $white;
    }
    }
  }
}

.card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue-light-90;
  color: $blue-primary;
  font-size: 30px;
  height: 60px;
  width: 60px;
  border-radius: 8px;
}

.song-image-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue-light-90;
  color: $blue-primary;
  font-size: 28px;
  height: 50px;
  width: 50px;
  border-radius: 4px;
}

// Workflows
.workflow-step-number {
  display: flex;
  flex: 0;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 2px solid $blue-primary;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  color: $blue-primary;
  // background-color: $gray1;
}

.workflow-step-checkbox {
  display: flex;
  flex: 0;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 2px solid #CCC;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &.selected {
    background-color: $blue-primary;
    border: 2px solid $blue-primary;
    color: $white;
  }
}

.collapse-music-prep {
  .ant-collapse-header {
    border-bottom: 1px solid #e8e8e8;
  }
  .ant-collapse-content-box {
    padding: 0px;
    // background-color: $gray9;
    border-bottom: 1px solid #e8e8e8;
    // border-radius: 8px;
  }
  .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding: 0px;
  }
}

.admin-calendar {
  &--header {

  }

  &--arrow {
    display: flex;
    // flex: 0;
    background-color: $blue-light-90;
    height: 36px;
    width: 36px;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 20px;
    color: $text-light-gray;
  }

  &--day-header {
    display: flex;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    background-color: $gray7;
    padding: 10px 0px;
    div {
      flex: 1;
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      color: $text-light-gray;
    }
  }

  &--week-row {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
  }

  &--cell {
    display: flex;
    flex: 1;
    padding: 12px 0px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &--cell-number {
    display: flex;
    font-weight: 700;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    &.today {
      background-color: $blue-light-90
    }
  }

  &--dot-container {
    display: flex;
    flex: 1;
    margin-top: 6px;
    flex-direction: row;
    height: 8px;
  }

  &--dot {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    margin: 0px 2px;
    &.time-off {
      background-color: #555555;
    }
    &.booked {
      background-color: $blue-primary;
    }
  }
}

.site-layout, .user-layout {
  .carousel.carousel-slider {
    overflow: unset;
  }

  .carousel .control-dots {
    bottom: -40px;
  }

  .carousel .control-dots .dot {
    background: #333;
    box-shadow: unset;
  }
}

.app-carousel {
  max-width: 340px;
  margin: 0 auto;

  &--image {
    img {
      max-width: 320px;
    }
  }

  @include for-large {
    &--image {
      img {
        max-width: 300px;
      }
    }
  }

  @include for-small {
    max-width: 100%;
    &--image {
      img {
        max-width: 280px;
      }
    }
  }
}

.app-image {
  max-width: 340px;
  @include for-large {
    max-width: 300px;
  }

  @include for-small {
    max-width: 280px;
  }
}

.blank-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $gray7;
  overflow-y: auto;
  overflow-x: hidden;
}

.profile-photo-container {
  display: inline-block;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: $blue-light-70;
  padding: 5px;
  &.gray-border {
    background-color: $black9;
  }
}

.profile-photo {
  position: relative;
  width: 140px;
  height: 140px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-color: #FFF;
  border: 2px solid #FFF;

  .photo-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: $black7;
    color: $white;
    border-radius: 50%;
    font-size: 18px;
    z-index: 10;
  }

  .photo-spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 136px;
    height: 136px;
    background-color: rgba(0, 0, 0, 0.6);
    color: $white;
    border-radius: 50%;
    font-size: 24px;
  }
}

.small-profile-photo {
  width: 40px;
  height: 40px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
  &.size-60 {
    width: 60px;
    height: 60px;
  }
  &.size-80 {
    width: 80px;
    height: 80px;
  }
}

.event-photo-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: $white;
  border-radius: 10px;
  font-size: 30px;
}

.gray-hover-card {
  background-color: #F9F9F9;
  border-radius: 8px;
  cursor: default;
  transition: background-color 0.2s;
  &:hover {
    background-color: #F5F5F5;
  }
}

.quick-link-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .quick-link-icon {
    font-size: 32px;
    color: $blue-primary;
    background-color: $blue-light-90;
    width: 52px;
    height: 52px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .quick-link-col-1 {
    flex: 0;
  }
  .quick-link-col-2 {
    flex: 1;
    padding-left: 15px;
  }
}

.doc-number {
  font-size: 16px;
  font-weight: 600;
  color: $blue-primary;
  background-color: $blue-light-90;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 0.4s;
  color: $black7;
  margin: 5px 10px;
  padding: 0px 8px 0px 16px;
  height: 40px;
  border-radius: 8px;

  &--icon {
    display: flex;
    flex: 0
  }
  &--title {
    padding-left: 10px;;
  }

  &.selected {
    background-color: $gray3;
    color: $blue-primary
  }

  &:hover {
    background-color: $gray9;
  }
}

.header-editable-title {
  height: 38px;

  &--label {
    font-size: 24px;
    font-weight: 700;
    padding-right: 5px;
    padding-left: 5px;
    margin-left: -5px;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #CCC;
    }
  }

  &--input {
    display: none;
  }

  &.editing {
    .header-editable-title--label {
      display: none;
    }
    .header-editable-title--input {
      display: inline-block;
      input {
        font-weight: 700;
        font-size: 24px;
        padding: 0px 5px;
        margin-left: -5px;
      }
    }
  }

  &.small {
    height: 32px;

    .header-editable-title--label {
      font-size: 18px;
    }

    &.editing {
      .header-editable-title--input {
        input {
          font-size: 18px;
          margin-left: 0px;
        }
      }
    }
  }
}

.toolbar-container {
  padding: 15px;
  text-align: center;
  margin-bottom: 5px;
  background-color: $white;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: flex-end;

  &.floating {
    margin: 15px 20px 0px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding-right: 5px;
  }
}
.toolbar-button {
  display: flex;
  flex: 0;
  background-color: $gray7;
  padding: 5px 10px;
  margin-right: 10px;
  color: $blue-primary;
  border-radius: 4px;
  cursor: default;
  user-select: none;
}
.toolbar-tab {
  flex: 0;
  padding: 0 10px 12px;
  color: $text-light-gray;
  font-weight: 600;
  margin-bottom: -15px;
  cursor: default;
  border-bottom: 3px solid $white;
  user-select: none;
  &.selected {
    color: $blue-primary;
    border-bottom: 3px solid $blue-primary;
  }
}

.hide-print {
  @media print {   
    display: none !important;
  }
}

// Editor.js
.ce-block__content, .ce-toolbar__content {
  max-width: 700px;
}
.editor-variable-field {
  border: none;
  outline: none;
  color: $text-color;
  font-weight: 600;
  background-color: #e1e9ff;
  border-bottom: 1px dashed #536DFE;
  padding: 2px 8px;
  cursor: pointer;

  &.disabled {
    background-color: #F7F7F7;
    border-bottom: 1px dashed #CCCCCC;
    cursor: default;
  }
}

.editor-auto-populated-section {
  background-color: #F7F7F7;
  padding: 15px 15px;
  border-left: 2px solid #CCC;
  margin-bottom: 10px;
}

.scrollable-dropdown {
  .ant-dropdown-menu {
    max-height: 200px;
    overflow: auto;
  }
}

.ProseMirror {
  p {
    margin-bottom: 10px;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }
}

.ProseMirror-focused {
  outline-style: none;
}

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.signature-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e1e9ff;
  border-bottom: 1px dashed #536DFE;
  color: $blue-primary;
  padding: 10px 15px;
  font-weight: 600;
  cursor: pointer;
}

.signature-field-readonly {
  font-family: 'Caveat', cursive;
  font-size: 24px;
  margin-left: 10px;
  line-height: 1.2;
}

.signature-line {
  border-bottom: 1px solid #CCC;
  margin-top: 5px;
}

.signature-pad {
  background-color: $gray7;
  padding: 10px 20px 20px 20px;

  input {
    background: transparent;
    border: none;
    border-bottom: 1px solid #CCC;
    font-family: 'Caveat', cursive;
    font-size: 40px;
    &:focus {
      box-shadow: none;
    }
  }
}

.event-contract-stepper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &--step {
    display: flex;
    flex-direction: column;
    flex: 0;
    align-items: center;

    &.selected {
      .event-contract-stepper--step-text {
        color: $blue-primary;
        font-weight: 700;
      }
      .event-contract-stepper--step-circle {
        background-color: $blue-primary;
        color: $white;
      }
    }
  }
  &--step-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: #CCC;
    color: $text-light-gray;
    font-size: 16px;
  }
  &--step-text {
    color: $text-light-gray;
    // font-weight: 700;
    margin-top: 10px;
  }
  &--line {
    border-bottom: 1px solid #CCC;
    flex: 1;
    margin-top: 20px;
  }
}

.event-contract {
  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $gray7;
    overflow-x: hidden;

    @media print {   
      overflow-x: visible;
    }
  }

  &--body {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
  }

  &--header {
    background-color: $white;
    border-bottom: 1px solid #dedede;
  }

  &--sider {
    background-color: $white;
    width: 300px;
    border-right: 1px solid #dedede;
    transition: left .4s;

    @include for-medium {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -300px;
      z-index: 5;

      &.open {
        left: 0;
      }
    }
  }

  &--content {
    flex: 1;
    overflow-y: auto;
  }

  &--footer {
    padding: 15px 20px;
    position: fixed;
    bottom: 0px;
    left: 350px;
    right: 0px;
    z-index: 1;
    background-color: $white;
    border-top: 1px solid #dedede;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.planning-tab-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;

  &--icon {
    background-color: $blue-light-90;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    flex: 0;
    flex-basis: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: $blue-primary;
    font-size: 36px;
  }
}

.special-event-add-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue-light-90;
  color: $blue-primary;
  font-size: 20px;
  height: 30px;
  width: 30px;
  border-radius: 4px;
}

.message-box {
  background-color: $blue-light-90;
  padding: 15px;
  border-radius: 4px;
  color: $blue-primary;
  display: flex;
  flex-direction: row;
  border: 1px solid $blue-light-80;
  font-size: 12px;
  align-items: center;
  .message-icon {
    flex: 0;
    font-size: 18px;
    padding-top: 0px;
    margin-right: 15px;
  }
  .message-text {
    flex: 1;
  }
  &.warning {
    background-color: $red-light-90;
    color: $red;
    border: 1px solid $red-light-80;
  }
  &.success {
    background-color: $green-light-90;
    color: $green;
    border: 1px solid $green-light-80;
  }
}

.status-tag {
  display: inline;
  background-color: $blue-light-90;
  color: $blue-primary;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 600;
  user-select: none;
  white-space: nowrap;

  &.red {
    background-color: #FFF1F0;
    color: #CF1322;
  }
  &.orange {
    background-color: #fdf2e9;
    color: #e67e22;
  }
  &.yellow {
    background-color: #FFFBE6;
    color: #D48806;
    border: 1px solid #faf4d7;
  }
  &.green {
    background-color: #e7f7f3;
    color: #10ac84;
  }
  &.purple {
    background-color: #f4ecf7;
    color: #8e44ad;
  }
  &.gray {
    background-color: #eef0f1;
    color: #576574;
  }
  &.black {
    background-color: #cccccc;
    color: #000;
  }
  &.medium {
    font-size: 12px;
  }
  &.large {
    font-size: 14px;
  }
  &.full-width {
    display: block;
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 10px;
  }
}

.pdf-viewer {
  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $gray7;
    overflow-x: hidden;

    @media print {   
      overflow-x: visible;
    }
  }

  &--body {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
  }

  &--header {
    background-color: $white;
    // border-bottom: 1px solid #dedede;
  }
}

.subpage-layout {
  display: flex;
  justify-content: center;
  
  &--body {
    max-width: 1000px
  }

  &--menu {
    width: 200px;
    position: fixed;
    overflow-y: auto;
    padding-left: 15px;
  }

  &--content {
    margin-left: 200px;
    padding: 20px;
    overflow-y: auto;
    &.collapsed {
      margin-left: 0px;
    }
  }
}