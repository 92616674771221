// Stripe Checkout fields
.App-Payment {
    border: none;
}

.StripeElement {
    padding: 20px;
    border: 1px solid #d9d9d9;
}

// .StripeElement--empty {
//     border: 1px solid $red;
// }

.StripeElement--invalid {
    border: 1px solid $red;
}

.StripeElement--focus {
    border: 1px solid #CCC;
}

.Input {
    border: 1px solid $red;
}