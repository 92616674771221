.white-outline-button {
  @include white-outline-button;
}

.black-outline-button {
  @include black-outline-button;
}

.primary-button {
  height: 48px;
  background-color: $blue-primary;
  width: 100%;
  color: $white;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  transition: background-color .4s, color .4s;
  margin-top: 14px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:hover {
    cursor: default;
    background-color: $blue-primary-hover;
  }

  &.secondary {
    background-color: transparent;
    color: $blue-primary;
    border: 1px solid $blue-primary;
  }

  &.warning {
    background-color: #ff6f6d;
  }

  &.disabled {
    background-color: #CCC;
  }
}

.small-primary-button {
  height: 36px;
  display: inline-block;
  padding: 0px 20px;
  background-color: $blue-primary;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  border: none;
  transition: background-color .4s, color .4s;
  white-space: nowrap;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  &:hover {
    cursor: default;
    background-color: $blue-primary-hover;
  }
  &.secondary {
    background-color: transparent;
    color: $blue-primary;
    border: 1px solid $blue-primary;
  }
}

.small-secondary-button {
  height: 36px;
  display: inline-block;
  padding: 0px 20px;
  background-color: $white;
  border: 1px solid $blue-primary;
  color: $blue-primary;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  transition: background-color .4s, color .4s;
  white-space: nowrap;
  &:hover {
    cursor: default;
    background-color: $blue-light-90;
  }
}

.gray-icon-button {
  display: flex;
  background-color: $gray1;
  height: 32px;
  width: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 20px;
  color: #999;
  &:hover {
    background-color: #eeeeee;
  }
}

.credit-payment-button {
  display: flex;
  background-color: #2C2E2F;
  height: 45px;
  color: $white;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  cursor: pointer;
  &:hover {
    filter: brightness(1.2);
  }
}

.action-button {
  border: 1px solid #707070;
  background-color: $white;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #707070;
  cursor: default;
}

.filter-button {
  background: #F7F7F7;
  display: inline-flex;
  height: 40px;
  padding-left: 10px;
  padding-right: 15px;
  cursor: default;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  align-items: center;
  transition: background-color .4s, color .4s;
  user-select: none;
  &:hover {
    background: #ebebeb;
  }
  &.icon-right {
    padding-left: 15px;
    padding-right: 10px;
  }
  &.filter-tag {
    padding-left: 15px;
    padding-right: 10px;
    background: $blue-light-90;
  }
}