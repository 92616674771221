.admin-event-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: white;
  padding: 10px 10px 10px 0;
  margin-left: 10px;
  // margin-bottom: 12px;
  // border-radius: 8px;
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: background-color .4s;

  &:hover {
    cursor: default;
    background-color: #f9f9f9;
  }

  &--col-2 {
    flex: 1;
    padding: 0px 15px;
    min-width: 0;
  }

  &--col-3 {
    display: flex;
    flex: 0;
    padding: 0px 5px;
  }

  .event-box-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
  }

  .event-box-location {
    color: #909090;
    line-height: 1.2;
    margin-top: 5px;
  }
}

.admin-event-card-date-box {
  background-color: #f1f1f1;
  display: flex;
  flex: 0;
  padding: 5px;
  border-radius: 8px;

  .admin-event-card-date-inner {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .event-day {
      flex: 0;
      font-size: 8px;
      font-weight: 600;
    }

    .event-date {
      flex: 0;
      color: $blue-primary;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }

    .event-year {
      flex: 0;
      font-size: 10px;
      font-weight: 600;
    }
  }
}

.event-status-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.event-status-slider {
  width: 100%;
  max-width: 100%;
  flex: 1;
  overflow-x: scroll;
  white-space: nowrap;
  scroll-behavior: smooth;
  padding: 10px 0px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.event-status-arrow-container {
  width: 35px;
  height: 140px;
  flex: 0;
  display: flex;
  align-items: center;
  transition: background-color .2s;
  &:hover {
    background-color: #e1e1e1cc;
  }

  @media (max-width: 768px) {
    height: 105px;
  }
}

.event-status-arrow {
  color: #909090;
}

.event-status-card {
  background-color: #FAFAFA;
  width: 150px;
  height: 120px;
  border: 1px solid #CCC;
  margin: 0px 5px;
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  color: #404040;
  transition: transform .2s, background-color .2s;
  user-select: none;

  &:hover {
    transform: scale(1.05);
  }

  &.all-stage {
    background-color: #606060;
    color: #FFF;
    margin-right: 20px;
  }

  &.selected {
    background-color: $blue-primary;
    color: #FFFFFF;
    border: 1px solid $blue-primary;
  }

  &--label {
    text-align: center;
    text-wrap: initial;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    margin-top: 20px;
    overflow: hidden;
    line-height: 16px;
  }

  &--data {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    line-height: 40px;
  }

  @media (max-width: 768px) {
    width: 100px;
    height: 85px;

    &--label {
      font-size: 10px;
      height: 26px;
      margin-top: 10px;
      line-height: 12px;
    }

    &--data {
      text-align: center;
      font-weight: 300;
      font-size: 30px;
      line-height: 30px;
    }
  }
}