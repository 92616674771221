@import '../../styles/globals.scss';

.site-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $gray7;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  // background-color: $gray7;
  // min-height: 100vh;
  // display: flex;

  &--header {
    display: flex;
    background-color: $white;
    width: 100%;
    position: fixed;
    z-index: 5;
    height: $header-height;
    padding: 0 20px;
    // box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, .1);
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; // Stripe
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; // Material
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  &--menu-item {
    cursor: pointer;
    color: $text-light-gray;
    font-weight: 600;
    padding: 0 15px;
    transition: color 0.5s;

    &:hover {
      color: $black;
    }
  }

  &--body {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: $header-height;
  }

  &--logo {
    display: flex;
    img {
      height: 48px;
    }
  }

  &--main {
    width: 100%;
  }

  &--main-center-content {
    margin: 0 auto;
    max-width: 1000px;
  }

}