@mixin for-large {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin for-medium {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin for-small {
  @media (max-width: 480px) {
    @content;
  }
}