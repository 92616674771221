@import '../../styles/globals.scss';

.admin-content {
  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &--header {
    // position: fixed;
    // top: $header-height;
    // left: $side-menu-width;
    // right: 0;
    flex: 0;
    z-index: 2;
    background-color: #FFF;
    border-bottom: 1px solid #dedede;
  }

  &--body {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow-y: auto;
    position: relative;
  }

  &--body-main {
    flex: 1;
    overflow-y: auto;
    // padding: 20px;
    // @include for-small {
    //   padding: 15px;
    // }
  }

  &--body-main-content {
    width: 100%;
    max-width: 1000px;
  }

  &--body-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 400px;
    display: flex;
    flex: 1;
    flex-direction: column;
    // position: fixed;
    // top: $header-height;
    // right: 0;
    // bottom: 0;
    // flex: 0;
    // background-color: $white;
    // border-left: 1px solid #dedede;
    // width: 400px;
    // flex-basis: 400px;
    // overflow-y: auto;
  }
}