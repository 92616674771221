@import '../../styles/globals.scss';

.user-layout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $gray7;
  // min-height: 100vh;
  display: flex;
  overflow-y: auto;

  &--header {
    display: flex;
    background-color: $white;
    width: 100%;
    position: fixed;
    z-index: 5;
    height: $header-height;
    padding: 0 20px;
    // box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, .1);
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; // Stripe
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; // Material
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  &--body {
    display: flex;
    flex: 1;
    padding-top: $header-height;
  }

  &--logo {
    display: flex;
    img {
      height: 48px;
      // @media (max-width: 767px) {
      //   display: none;
      // }
    }
  }

  &--sider {
    background-color: $white;
    position: fixed;
    top: $header-height;
    left: 0;
    bottom: 0;
    width: 240px;
    border-right: 1px solid #dedede;
    padding-top: 20px;
    z-index: 4;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &--main {
    width: 100%;
    display: flex;
  }

  .profile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 5px;
    border-radius: 8px;
    transition: 0.5s background-color;
    &:hover {
      cursor: default;
      background-color: $gray9;
    }
  }

  .profile-circle {
    background-color: $blue-light-90;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    flex: 0;
    flex-basis: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: $blue-primary;
    font-size: 20px;
    // margin-right: 10px;
    &:hover {
      cursor: default;
    }
  }

  .profile-name {
    flex: 1;
    font-weight: 500;
    margin-left: 10px;
  }
}

.mobile-profile {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
  .mobile-profile-circle {
    background-color: $blue-light-90;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    flex: 0;
    flex-basis: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: $blue-primary;
    font-size: 40px;
  }
}