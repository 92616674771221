@import '../../../styles/globals.scss';

.login-form-circle-container {
  display: flex;
  justify-content: center;
  padding: 40px 20px 0px;
  .login-form-circle {
    display: flex;
    background-color: #f1f1f1;
    height: 120px;
    width: 120px;
    border-radius: 60px;
    align-items: center;
    justify-content: center;
  }
}

.login-form-logo {
  text-align: center;
  padding: 30px 0px 20px;
  margin: 0 40px;
}

.login-form-title {
  text-align: center;
  padding: 30px 20px 20px;
  font-weight: 700;
  font-size: 24px;
}

.login-form-name {
  text-align: center;
  padding: 10px 20px 0px;
  font-weight: 600;
  font-size: 16px;
}

.login-form-email {
  text-align: center;
  padding: 0px 20px 0px;
  font-size: 14px;
}

.login-form-back {
  text-align: center;
  color: $blue-primary;
  padding-bottom: 20px;
  cursor: default;
}