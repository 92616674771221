@import './globals.scss';

.display-flex { display: flex }
.display-inline-flex { display: inline-flex }
.flex-1 { flex: 1 }
.flex-0 { flex: 0 }
.flex-row { display: flex; flex-direction: row }
.flex-col { display: flex; flex-direction: column }
.flex-middle { align-items: center;}
.flex-end { align-items: flex-end;}
.justify-items-center { justify-items: center;}
.justify-content-center { justify-content: center;}
.justify-content-end { justify-content: flex-end;}
.nowrap { white-space: nowrap;}

.flex-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hide-small {
  @include for-small {
    display: none;
  }
}

.hide-medium {
  @include for-medium {
    display: none;
  }
}

.show-medium {
  display: none;
  @include for-medium {
    display: inherit;
  }
}