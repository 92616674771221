// Blues
$blue-primary: #536DFE;
$blue-primary-hover: #4b62e5;

$blue-light-10: #647cfe;
$blue-light-20: #758afe;
$blue-light-30: #8799fe;
$blue-light-40: #98a7fe;
$blue-light-50: #a9b6ff;
$blue-light-60: #bac5ff;
$blue-light-70: #cbd3ff;
$blue-light-80: #dde2ff;
$blue-light-90: #eef0ff;

$blue-dark-10: #4b62e5;
$blue-dark-20: #4257cb;
$blue-dark-30: #3a4cb2;
$blue-dark-40: #324198;
$blue-dark-50: #2a377f;
$blue-dark-60: #212c66;
$blue-dark-70: #19214c;
$blue-dark-80: #111633;
$blue-dark-90: #111633;

// White
$white: #FFFFFF;

// Black
$black: #000000;
$black1: #101010;
$black2: #202020;
$black3: #303030;
$black4: #404040;
$black5: #505050;
$black6: #606060;
$black7: #707070;
$black8: #808080;
$black9: #909090;

// Gray
$gray1: #F2F2F2;
$gray3: #F3F3F3;
$gray5: #F5F5F5;
$gray7: #F7F7F7;
$gray9: #F9F9F9;

$red: #e74c3c;
$red-hover: #d04436;
$red-light-80: #fadbd8;
$red-light-90: #fdedec;

$green: #00b894;
$green-light-80: #ccf1ea;
$green-light-90: #e6f8f4;

$button-hover: #4877DA;

$text-color: #424851;
$text-light-gray: #777777;
$text-gray-ccc: #CCC;