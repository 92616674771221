.calendar {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  
  &--menu {
    width: 350px;
    position: fixed;
    overflow-y: auto;
    right: 0px;
    top: 144px;
    bottom: 0px;
    background-color: #FFFFFF;
    border-left: 1px solid #e8e8e8;
    transition:left 0.3s linear;
    left: calc(100vw - 350px);

    &--close-button {
      display: none;
    }

    @media (max-width: 1200px) {
      left: 100vw;
      &.show {
        left: calc(100vw - 350px);
      }
      &--close-button {
        display: inline-block;
      }
    }
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
  }

  &--content {
    margin-right: 350px;
    padding: 20px;
    overflow-y: auto;
    max-width: 1500px;
    @media (max-width: 1200px) {
      margin-right: 0px;
    }
  }

  &--cell {
    flex: 1;
    padding-bottom: 4px;
    border-right: 1px solid #e8e8e8;
    min-height: 120px;
    position: relative;
    overflow: hidden;
    @media (max-width: 1500px) {
      min-height: 100px;
    }
    @media (max-width: 575px) {
      min-height: 70px;
    }
  }

  &--cell-date {
    margin: 5px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    &.today {
      background-color: $blue-primary;
      color: white;
    }
  }

  &--calendar-dot {
    display: inline-block;
    background-color: $blue-primary;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-left: 2px;
    margin-right: 2px;
    &.time-off {
      background-color: #555555;
    }
  }

  &--filter {
    display: flex;
    background-color: $gray1;
    height: 36px;
    width: 36px;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 20px;
  }
}