@import '../../../styles/globals.scss';

.home-page {
  background-color: $blue-primary;
  min-height: 100vh;
  display: flex;
  align-items: center;

  .home-page-content {
    flex: 1;
    text-align: center;
  }

  .home-title {
    font-size: 80px;
    font-weight: 600;
    line-height: 1;
    color: $white;
    margin-bottom: 24px;

    @include for-medium {
      font-size: 50px;
    }

    @include for-small {
      font-size: 40px;
    }
  }
}

  .home-button {
    width: 250px;
    height: 60px;
    font-size: 18px;
    text-transform: uppercase;
    background-color: $blue-primary;
    color: white;
    border: none;
    font-weight: 600;
    display: inline-block;
    transition: background-color .4s, color .4s;
    border-radius: 8px;
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    &:hover {
      cursor: default;
      background-color: $blue-light-10;
    }
    &:focus {
      outline: none
    }

    @include for-small {
      width: 150px;
      height: 48px;
      font-size: 14px;
    }
  }