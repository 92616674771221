@import '../../styles/globals.scss';

.ant-menu-horizontal {
  float: right;
  height: 64px;

  > .ant-menu-item, > .ant-menu-submenu {
    font-weight: 500;
    color: $black7;
    border-bottom: none;
    top: 0;

    &-active, &:hover {
      background-color: $gray9;
      color: $black7;
      border-bottom: none;
    }

    &-selected {
      &:hover {
        background-color: $gray1;
        color: $black3;
      }
    }

    > .ant-menu-submenu-title {
      color: $black7;
      &:hover {
        color: $black7;
      }
    }
  }

  @include for-medium {
    display: none;
  }
}

.ant-menu:not(.ant-menu-horizontal) {

  > .ant-menu-item {
    font-weight: 500;
    color: $black7;
    margin: 5px 10px;
    width: 210px;
    padding-left: 15px !important;
    border-radius: 8px;

    &:after {
      border-right: none;
    }

    &-active, &:hover {
      background-color: $gray9;
      color: $black7;
      border-right: none;
    }

    &-selected {
      background-color: $gray3;
      color: $blue-primary;
      &:hover {
        background-color: $gray3;
        color: $blue-primary;
      }
    }
  }

  .ant-menu-submenu {
    font-weight: 500;
    color: $black7;

    &-title {
      color: $black3;
      padding-left: 15px !important;
      font-weight: 600;
      font-size: 18px;
      &:hover {
        color: $black7;
      }
    }

    &-active, &:hover {
      background-color: $gray9;
      color: $black7;
      &:hover {
        color: $black7;
      }
    }

    .ant-menu-item {
      padding-left: 15px !important;
    }
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: $white;
  }
}

.item-selected {
  background-color: $gray1;
  color: $black3;
}