@import '../../../styles/globals.scss';

.signup-page {
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // background-color: $gray1;
  padding: 30px;
  padding-top: 50px;
  overflow-y: auto;

  .signup-header-title {
    font-size: 40px;
    font-weight: 300;
    color: #505050;
  }

  .signup-header-subtitle {
    font-size: 14px;
    color: #707070;
    margin-bottom: 20px;
  }
}

.signup-box {
  flex: 0;
  background-color: $white;
  margin: 10px 0 30px 0;
  border: 1px solid #eee;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, .1);
  width: 100%;
  max-width: 600px;
  border-radius: 5px;

  .signup-title {
    padding: 20px;
    font-weight: 500;
    font-size: 24px;
    border-bottom: 1px solid #E0E0E0;
  }

  .signup-form {
    padding: 40px;
  }
}

.login-error {
  text-align: center;
  color: $red;
  padding-bottom: 10px;
}

.login-button {
  background-color: $blue-primary;
  padding: 10px 0;
  width: 100%;
  color: $white;
  border: none;
  outline: none;
  font-size: 18px;
  border-radius: 6px;
}